import { Button, Col, DatePicker, Row, Select, Skeleton, Spin, Table } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { CoreSearchBox } from '../../components/core-components'
import { TitleLevel3 } from '../../components/general-component'
import { Constant } from '../../core/constant'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiInstance } from '../../network/network-manager'
import dayjs from 'dayjs';
import { current } from '@reduxjs/toolkit'

const { RangePicker } = DatePicker;

class FuelPointHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: Constant.page,
            pageSize: Constant.pageSize,
            pageNumber: 0,
            totalPagination: 0,
            searchValue: [],
            data_filter_params: '',
            prevSearchTerm: '',
            searchValue: [],
            data: [],
            isDataFetching: true,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async (pageNumber, value) => {

        const { page, searchValue, pageSize, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                page: pageNumber ? pageNumber - 1 : page - 1,
            };
            const response = await ApiInstance(Api.fuel_point_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                    isDataFetching: false
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
            searchValue: value ? value : searchValue
        })
    }

    search = async () => {
        const { page, pageNumber, searchTerm, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                page: pageNumber ? pageNumber - 1 : page - 1,
            };

            const response = await ApiInstance(Api.fuel_point_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event, searchName) => {
        let { data_filter_params } = this.state;
        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value
        };

        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target,
            page: 1,
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );

        }
    }

    handleKeyDown = (e) => {
        this.search();
    };

    handleDateChange = (dates) => {
        const { data_filter_params, pagination } = this.state

        const fromDate = dates ? dates[0].format('YYYY-MM-DDTHH:mm:ss') : null;
        const toDate = dates ? dates[1].format('YYYY-MM-DDTHH:mm:ss') : null;

        this.setState(
            {
                data_filter_params: {
                    ...data_filter_params,
                    fromDate,
                    toDate,
                    page: 0, // Reset to first page on new search
                },
                page : 1,
                pagination: {
                    ...pagination,
                    current: 1, // Reset pagination to page 1
                },
                current : 1,
            },
            () => {
                this.search();
            }
        );
    };


    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    filterByHistoryType = (value, option) => {

        console.log(value)

        const { data_filter_params } = this.state;

        let params;

        params = {
            ...data_filter_params,
            historyType: value === "ALL" ? '' : value,
        }

        this.setState({
            data_filter_params: params,
            page : 1,
            current : 0
        }, () => this.search()
        )
    }

    render() {
        const { data, page, pageSize, isDataFetching, options, totalPagination } = this.state
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: i18next.t('from_user'),
                dataIndex: ['fromUser', 'fullName'],
                key: 'admin',
            },
            {
                title: i18next.t('to_user'),
                dataIndex: ['toUser', 'fullName'],
                key: 'toUser',
            },
            {
                title: i18next.t('points'),
                dataIndex: 'fuelPoint',
                key: 'point',
            },
            {
                title: i18next.t('date'),
                dataIndex: 'createdDate',
                key: 'date',
                render: (date) => dayjs(date).format('YYYY-MM-DD'),
            },
            {
                title: i18next.t('history_type'),
                dataIndex: 'historyType',
                key: 'historyType',
            },
        ]
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TitleLevel3 label={i18next.t("fuel_point_history")} />
                    </Col>

                    <Col span={4}><CoreSearchBox
                        size="middle"
                        onChange={(values) =>
                            this.changeSearchName(values, "fromUserName")}
                        placeholder="SearchByFromUser"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={4}><CoreSearchBox
                        size="middle"
                        onChange={(values) => this.changeSearchName(values, "toUserName")}
                        placeholder="SearchByToUser"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={3}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >{i18next.t('search')}</Button>
                    </Col>
                    <Col span={9} style={{textAlign: 'end'}}>
                        <RangePicker
                            format="YYYY-MM-DD"
                            onChange={this.handleDateChange}
                            style={{ height: '40px' }}
                        />
                    </Col>
                    <Col span={4} style={{ textAlign: 'end' }}>
                        <Select
                            showSearch
                            style={{ height: 40, width: '100%', textAlign: 'start' }}
                            placeholder="Filter By HistoryType"
                            onChange={this.filterByHistoryType}
                            options={[
                                {
                                    value: 'ALL',
                                    label: 'ALL',
                                },
                                {
                                    value: 'TOPUP',
                                    label: 'TOPUP',
                                },
                                {
                                    value: 'REQUEST',
                                    label: 'REQUEST',
                                },
                            ]}
                        >

                        </Select>
                    </Col>
                    <Col lg={{ span: 24 }}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table pagination={{
                                    position: "top" | "bottom",
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                }}
                                    dataSource={data} columns={columns} />
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default FuelPointHistory