import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Card, Col, Form, Radio, Row } from 'antd';
import dayjs from 'dayjs';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CoreBackButton, CoreCreateButton, CoreDatePicker, CoreInput } from '../../components/core-components';
import CoreNRCInput from '../../components/nrcInput/nrc-input';
import { validatePhoneNumber } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import { CustomPath } from '../../routes/custom-path';
import NRCInput from '../../components/nrcInput/nrc-input';
export class StaffCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: '',
      stationId: this.props.params.stationid,
      on_create_loading: false,
      accountType: '',
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: <Link to={CustomPath.staff}>{i18next.t("staff")}</Link>,
        },
        {
          title: i18next.t("staff.create"),
        },
      ],
    }
    this.formRef = React.createRef();
  }
  componentDidMount() {
    const date = dayjs();
    this.setState({ currentDate: date.subtract(18, 'year') })
  }
  disabledDate = (current) => {
    const eighteenYearsAgo = dayjs().subtract(18, 'year');
    return current && current > eighteenYearsAgo;
  };

  currentDate = () => {
    console.log(dayjs().subtract(18, 'year'))
    return dayjs().subtract(18, 'year')
  }

  saveFuelStaff = async (values) => {
    const { adminId, stationId, on_create_loading } = this.state
    console.log("values:", values);
    
    const { nrc } = values;

    const formattedNRC = `${nrc.nrcCode}/${nrc.township}(${nrc.nrcType})${nrc.nrcNumber}`;

    this.setState({
      on_create_loading: true
    })

    const requestData = {
      "uniqueId": values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "emailAddress": values.emailAddress,
      "address": values.address,
      "nrc": formattedNRC,
      "status": values.status,
      "phoneNumber": values.phoneNumber,
      "userType": values.userType,
      "dateOfBirth": values.dateOfBirth,
      "accounts": [
        {
          "password": values.password,
          "accountType": 'GENERATE'
        }
      ],
      "fuelStation": {
        "id": stationId
      }
    }

    try {
      const response = await ApiInstance(
        Api.staff, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.props.navigate(stationId ? `${CustomPath.station}/${stationId}/${CustomPath.staff}` : `/${CustomPath.staff}`)
      }
    } catch (error) {
      console.log("error in save user");
      console.log(error);
      this.setState({
        on_create_loading: false
      })
    }
  };

  accountTypeChange = (e) => {
    this.setState({
      accountType: e.target.value
    })
  };

  handleDateChange = (date, dateString) => {
    if (this.formRef?.current) {
      this.formRef.current.setFieldsValue({ dateOfBirth: dateString });
    }
  };

  render() {
    const { items, on_create_loading, stationId, accountType } = this.state;
    return (

      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Breadcrumb items={this.state.items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("staff.create")}</h3>}
              bordered={false}
              extra={
                <CoreBackButton
                  path={stationId ? `${CustomPath.station}/${stationId}/${CustomPath.staff}` : `/${CustomPath.staff}`}
                  type="primary"
                  text={i18next.t("back")}
                ></CoreBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.saveFuelStaff}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="emailAddress"
                      label={i18next.t("email")}
                      rules={[{ required: true, message: "(*required)" },
                      {
                        type: 'email',
                        message: 'The input is not a valid email!',
                      },]}
                    >
                      <CoreInput
                        size="large"
                        placeholder={i18next.t("email")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="phoneNumber"
                      label={i18next.t("phone_number")}
                      rules={[
                        { validator: validatePhoneNumber }
                      ]}
                    >
                      <CoreInput size="large" placeholder="979xxxxxxx" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="password"
                      label={i18next.t("password")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <CoreInput
                        size="large"
                        isPasswordInput={true}
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="userType"
                      label={i18next.t("user_type")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <Radio.Group>
                        <Radio value="FUEL_MANAGER">{i18next.t("fuel_manager")}</Radio>
                        <Radio value="FUEL_STAFF">{i18next.t("fuel_staff")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                      <NRCInput name="nrc" label="NRC" required />
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                      <CoreDatePicker handleDateChange={this.handleDateChange}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                      <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item name="address" label={i18next.t("address")}>
                      <CoreInput
                        isTextareaInput={true}
                        style={{
                          backgroundColor: '',
                          border: "",
                        }}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <CoreCreateButton loading={on_create_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* ) 
    } */}

      </>
    )
  }
}

export default compose(withRouter(StaffCreate))