export const CustomPath = {
    login: 'login',
    staff: 'staff',
    staff_param: 'station/:stationid/staff',
    staff_create: 'staffcreate',
    staff_update: 'staffupdate',
    manager_staff_update_param: '/staffupdate/:id',
    staff_create_param: '/station/:stationid/staff/staffcreate',
    staff_update_param: '/station/:stationid/staff/staffupdate/:id',
    report: 'report',

    driver : '/driver',
    driver_create : '/driver/drivercreate',
    driver_update : '/driver/driverupdate',
    driver_update_param : '/driver/driverupdate/:id',

    vehicle : '/vehicle',
    vehicle_create : '/vehicle/vehiclecreate',
    vehicle_update : '/vehicle/vehicleupdate',
    vehicle_update_param : '/vehicle/vehicleupdate/:id',

    station: '/station',
    station_create: '/station/stationcreate',
    station_update: '/station/stationupdate',
    station_update_param: '/station/stationupdate/:id',

    daily_report : '/dailyreport',

    point_requests: 'fuelpointrequests',
    fuel_point_history : "/fuelpointhistory",

    station_point_history : '/stationpointhistory'


}