import { compose } from '@reduxjs/toolkit';
import { Breadcrumb, Card, Col, Form, Radio, Row } from 'antd';
import dayjs from 'dayjs';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CoreBackButton, CoreDatePicker, CoreInput, CoreUpdateButton } from '../../components/core-components';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from "../../network/withRouter";
import { CustomPath } from '../../routes/custom-path';
import { validatePhoneNumber } from '../../core/constant';
import { parseNRC } from '../../core/validation';
import NRCInput from '../../components/nrcInput/nrc-input';

export class StaffUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stationId: this.props.params.stationid,
      on_update_loading: false,
      staffAccount: {},
      prevLoginId: '',
      prevAccountType: '',
      accountType: '',
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: <Link to={CustomPath.staff}>{i18next.t("staff")}</Link>,
        },
        {
          title: i18next.t("staff.update"),
        },
      ],
    }
    this.updateRef = React.createRef()
  }
  componentDidMount() {
    this.fetchStaff()
  }

  fetchStaff = async () => {
    ApiInstance(Api.staff, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, this.props.params.id)
      .then(response => {
        this.setState({
          data: response,
          prevLoginId: response.accounts[0].loginId,
          prevAccountType: response.accounts[0].accountType,
          accountType: response.accounts[0].accountType
        })
              
        const initialNRCData = parseNRC(response.nrc);

        let staff;
        if (response.dateOfBirth) {
          const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
          staff = { ...response }
        } else {
          staff = { ...response }
        }
        if (response.status === 'ACTIVE') {
          staff = { ...staff, active: true }
        } else {
          staff = { ...staff, active: false }
        }
        if (staff.accounts) {
          staff.accounts.map((account) => {
            if (account.accountType === "PHONE") {
              staff = { ...staff }
              this.setState({
                staffAccount: account
              })
            }
          })
        }
        this.updateRef.current.setFieldsValue({...staff, nrc : initialNRCData})
      })
      .catch(error => { })
  }

  updateFuelStaff = async (values) => {
    const { data, staffAccount, stationId, prevLoginId, prevAccountType } = this.state

    const { nrc } = values;

    const formattedNRC = `${nrc.nrcCode}/${nrc.township}(${nrc.nrcType})${nrc.nrcNumber}`;

    this.setState({
      on_update_loading: true
    })
    const requestData = {
      'id': data.id,
      'uniqueId': values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "emailAddress": values.emailAddress,
      "dateOfBirth" : dayjs(values.dateOfBirth, 'YYYY-MM-DD'),
      "address": values.address,
      "nrc" : formattedNRC,
      "gender": values.gender,
      "status": values.status,
      "userType": values.userType,
      "phoneNumber": values.phoneNumber,
      "accounts": [
        {
          "loginId": prevLoginId,
          "password": values.password,
          "accountType": 'GENERATE'
        }
      ],
      "fuelStation": {
        'id': stationId
      }
    }

    try {
      const response = await ApiInstance(Api.staff, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData);

      if (response.status == 200) {
        this.props.navigate(stationId ? `${CustomPath.station}/${stationId}/${CustomPath.staff}` : `/${CustomPath.staff}`)
      }
    } catch (error) {
      this.setState({
        on_update_loading: false
      })
    }

  };

  accountTypeChange = (e) => {
    this.setState({
      accountType: e.target.value
    })
  };


  render() {
    const { items, on_update_loading, stationId, accountType } = this.state;
    return (

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb items={items} />
        </Col>
        <Col span={24}>
          <Card
            className="form"
            title={<h3>{i18next.t("staff.update")}</h3>}
            bordered={false}
            extra={
              <CoreBackButton
              path={stationId ? `${CustomPath.station}/${stationId}/${CustomPath.staff}` : `/${CustomPath.staff}`}
                type="primary"
                text={i18next.t("back")}
              ></CoreBackButton>
            }
          >
            <Form
              layout="vertical"
              onFinish={this.updateFuelStaff}
              ref={this.updateRef}
            >
              <Row gutter={24}>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="uniqueId"
                    label={i18next.t("unique_id")}
                    rules={[{ required: true, message: "(*required)" }]}
                  >
                    <CoreInput
                      size="large"
                      placeholder={i18next.t("unique_id")}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="firstName"
                    label={i18next.t("first_name")}
                    rules={[{ required: true, message: "(*required)" }]}
                  >
                    <CoreInput
                      size="large"
                      placeholder={i18next.t("first_name")}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="lastName"
                    label={i18next.t("last_name")}
                    rules={[{ required: true, message: "(*required)" }]}
                  >
                    <CoreInput
                      size="large"
                      placeholder={i18next.t("last_name")}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="emailAddress"
                    label={i18next.t("email")}
                    rules={[{ required: true, message: "(*required)" },
                    {
                      type: 'email',
                      message: 'The input is not a valid email!',
                    },]}
                  >
                    <CoreInput
                      size="large"
                      placeholder={i18next.t("email")}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="phoneNumber"
                    label={i18next.t("phone_number")}
                    rules={[
                      { validator: validatePhoneNumber }
                    ]}

                  >
                    <CoreInput size="large" placeholder="979xxxxxxx" />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="password"
                    label={i18next.t("password")}
                  >
                    <CoreInput
                      size="large"
                      isPasswordInput={true}
                      placeholder={i18next.t("password_placeholder")}
                    />
                  </Form.Item>
                </Col>

                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="userType"
                    label={i18next.t("user_type")}
                    rules={[{ required: true, message: "(*required)" }]}
                  >
                    <Radio.Group>
                      <Radio value="FUEL_MANAGER">{i18next.t("fuel_manager")}</Radio>
                      <Radio value="FUEL_STAFF">{i18next.t("fuel_staff")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                    <NRCInput name="nrc" label="NRC" required />
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="dateOfBirth"
                    label={i18next.t("date_of_birth")}
                  >
                    <CoreDatePicker />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="gender"
                    label={i18next.t("gender")}
                  >
                    <Radio.Group>
                      <Radio value="MALE">{i18next.t("male")}</Radio>
                      <Radio value="FEMALE">{i18next.t("female")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 12 }}>
                  <Form.Item
                    name="status"
                    label={i18next.t("status")}
                  >
                    <Radio.Group>
                      <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                      <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 24 }}>
                  <Form.Item name="address" label={i18next.t("address")}>
                    <CoreInput
                      isTextareaInput={true}
                      style={{
                        backgroundColor: '',
                        border: "",
                      }}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 24 }}>
                  <Form.Item style={{ textAlign: "end" }}>
                    <CoreUpdateButton loading={on_update_loading} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(StaffUpdate);