export const parseNRC = (nrc) => {
    const regex = /^(\d+)\/([A-Za-z]+)\((N|E)\)(\d{6})$/;
    const match = nrc.match(regex);
    if (match) {
      return {
        nrcCode: match[1],
        township: match[2],
        nrcType: match[3],
        nrcNumber: match[4],
      };
    }
    return null;
  };