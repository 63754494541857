import {
  FormOutlined
} from "@ant-design/icons";
import { Avatar, Col, Layout, Menu, Row } from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { SvgDailyReport, SvgDriver, SvgFuelPointRequests, SvgPointHistory, SvgStaff, SvgStation, SvgVehicle } from "./components/assets/custom-svg-icons";
import EdLogo from "./components/core-components";
import { Logout } from "./components/general-component";
import Api from "./network/api";
import { HTTP_METHOD } from "./network/httpMethod";
import { MEDIA_TYPE } from "./network/mediaType";
import { ApiInstance } from "./network/network-manager";
import { logoutAndResetReduxState } from "./redux/authTokenSlice";
import { CustomPath } from "./routes/custom-path";
const { Header, Content, Sider } = Layout;

const App = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = useSelector((state) => state.userType.value);
  const [count, setCount] = useState()

  function handleLogout() {
    dispatch(logoutAndResetReduxState())
    localStorage.clear();
    window.location.reload()
  }

   
    useEffect(() => {
      if (userType === 'COMPANY_ADMIN'){
      fetchPendingCount();
      const interval = setInterval(() => {
        fetchPendingCount();
      }, 500000);

      return () => clearInterval(interval);
    }
    }, [])

    const fetchPendingCount = async () => {
      try {
        const count = await ApiInstance(Api.fuel_point_pending_count, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null)
        console.log(count)
        setCount(count.pendingCount); // Assuming data is fetched from an API
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        // setLoading(false);
      }
    };
  

  return (
    <Layout
      style={{ 
        transform: 'scale(0.9)',
        transformOrigin: 'top left',
        width: '111.11vw',
        height: '111.11vh',
        position: 'absolute',
        top: '0',
        left: '0',
      }}
    >
      <Header
        theme='light'
        style={{
          background: 'white',
          height: "75px",
          display: "flex",
          alignItems: "center",
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          paddingLeft: 10,
          paddingRight: 10,
          zIndex: 2,
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}
      >
        <Col span={4} style={{ paddingTop: '25px' }}>
          <EdLogo />
        </Col>
        <Col span={16}></Col>
        <Col span={4} style={{ textAlign: 'end', paddingRight: '30px' }}>
          <Logout onClick={handleLogout} />
        </Col>
      </Header>
      <Layout>
        <Sider
          width={250}
          theme='light'
          style={{
            position: 'fixed',
            height: '100vh',
            left: 0,
            top: 55,
            bottom: 0,
            paddingTop: 20,
          }}
        >
          <Menu
            mode="inline"
            style={{
              height: "100%",
              borderRight: 0,
            }}
          >
            {userType === 'COMPANY_ADMIN' &&
              <>
                <MenuItem key='driver'>
                  <Link to={CustomPath.driver}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SvgDriver width={25} height={25} color="gray" />
                      <span className="sidebar-text">{t('driver')}</span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem key='vehicle'>
                  <Link to={CustomPath.vehicle}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SvgVehicle width={26} height={26} color="gray" />
                      <span className="sidebar-text">{t('vehicle')}</span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem key='fuelPointRequests'>
                  <Link to={CustomPath.point_requests}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SvgFuelPointRequests width={25} height={25} color="gray" />
                      <span className="sidebar-text">{t('fuel_point_requests')}</span>
                      {count ?
                        <Avatar
                          style={{
                            backgroundColor: 'red',
                            verticalAlign: 'middle',
                            marginLeft: 'auto',
                            fontSize: 15
                          }}
                          size={21}
                        // gap={1}
                        >
                          {count}
                        </Avatar>
                        : ""}
                    </div>
                  </Link>
                </MenuItem>
                <Menu.Item key="fuelPointHistory">
                  <Link to={CustomPath.fuel_point_history}>
                    <div style={{ display: "flex", alignItems: "center", }}>
                      <SvgPointHistory width={25} height={24} color="gray" />
                      <span className="sidebar-text">{t('fuel_point_history')}</span>
                    </div>
                  </Link>
                </Menu.Item>
              </>
            }
            {userType === 'FUEL_ADMIN' &&
              <>
                <MenuItem key='station'>
                  <Link to={CustomPath.station}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SvgStation width={24} height={24} color="gray" />
                      <span className="sidebar-text">{t('fuel_station')}</span>
                    </div>
                  </Link>
                </MenuItem>
                <Menu.Item key="fuelPointHistory">
                  <Link to={CustomPath.fuel_point_history}>
                    <div style={{ display: "flex", alignItems: "center", }}>
                      <SvgPointHistory width={25} height={24} color="gray" />
                      <span className="sidebar-text">{t('fuel_point_history')}</span>
                    </div>
                  </Link>
                </Menu.Item>
                <MenuItem key='daily_report'>
                  <Link to={CustomPath.daily_report}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SvgDailyReport width={25} height={25} color="gray" />
                      <span className="sidebar-text">{t('daily_report')}</span>
                    </div>
                  </Link>
                </MenuItem>
              </>
            }
            {userType === 'FUEL_MANAGER' &&
              <>
                <MenuItem key='1'>
                  <Link to={CustomPath.staff}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SvgStaff width={25} height={25} color="gray" />
                      <span className="sidebar-text">{t('staff')}</span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem key='2'>
                  <Link to={CustomPath.report}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormOutlined />
                      <span className="sidebar-text">{t('daily_report')}</span>
                    </div>
                  </Link>
                </MenuItem>
              </>
            }
          </Menu>
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Content
            style={{
              position: 'fixed',
              padding: 24,
              margin: 0,
              minHeight: 500,
              top: 60,
              left: 250,
              right: 0,
              bottom: 0,
              overflow: 'scroll',
              backgroundColor: '#f5f5f5',
              padding: "50px 30px 24px 30px",
            }}
          >
                <Outlet />

          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default App;
