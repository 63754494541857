import { EditOutlined } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Col, DatePicker, Row, Skeleton, Space, Table } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { CoreBackButton, CoreSearchBox, CreateButton } from '../../components/core-components';
import { CompanyTitle, TitleLevel3 } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from "../../network/withRouter";
import { CustomPath } from '../../routes/custom-path';

class StaffPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stationId: this.props.params.stationid,
      stationName: '',
      page: Constant.page,
      pageSize: Constant.pageSize,
      pageNumber: '',
      totalPagination: 0,
      deleteItem: "",
      data: [],
      columns: [
        {
          title: 'No.',
          key: 'index',
          render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
          width: 5
        },
        {
          title: i18next.t('unique_id'),
          dataIndex: 'uniqueId',
          key: 'uniqueId',
        },
        {
          title: i18next.t('login_id'),
          dataIndex: 'accounts',
          key: 'loginId',
          render: (account) => account[0].loginId
        },
        {
          title: i18next.t('name'),
          dataIndex: 'fullName',
          key: 'name',
        },
        {
          title: i18next.t('email'),
          dataIndex: 'emailAddress',
          key: 'emailAddress',
        },
        {
          title: i18next.t('phone_number'),
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
        },
        {
          title: i18next.t('address'),
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Action',
          key: 'action',
          dataIndex: '',
          textAlign: 'center',
          width: 60,
          render: (data) => (
            <Space>
              <Link to={this.state.stationId ? `${CustomPath.staff_update}/${data.id}` : `/${CustomPath.staff_update}/${data.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
            </Space>
          ),
        },
      ],
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: i18next.t('admin'),
        },
      ],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchStation()
  }

  fetchData = async (pageNumber) => {
    const { page, user_filter_params, stationId } = this.state;
    const params = {
      ...user_filter_params,
      page: pageNumber ? pageNumber - 1 : page - 1,
      fuelStationId: stationId
    };
    await ApiInstance(Api.staff_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      .then(response => {
        this.setState({
          data: response?.content,
          totalPagination: response?.totalElements,
          page: pageNumber ? pageNumber : page,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
      })
  }

  search = async () => {
    const { page, pageNumber, searchTerm, data_filter_params, stationId } = this.state
    this.setState({
      isDataFetching: true
    })
    console.log("fetch")
    try {
      const params = {
        ...data_filter_params,
        fuelStationId: stationId
      };

      const response = await ApiInstance(Api.staff_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: page,
          prevSearchTerm: searchTerm
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false
    })
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  changeSearchName = (event, searchName) => {
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      [searchName]: event.target.value,
    }
    console.log(data_filter_params);
    this.setState({
      data_filter_params: data_filter_params,
      searchTerm: event.target
    });

    if (event.target.value === '' && this.state.prevSearchTerm) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          [searchName]: '',
        },
        prevSearchTerm: ''
      }, () =>
        this.search()
      );
    }
  }

  searchByDob = (date, dateString) => {
    console.log(date, dateString);
    let { data_filter_params } = this.state;



    if (date === null) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          dateOfBirth: ''
        }
      }, () =>
        this.search()
      );

    }
    else {
      data_filter_params = {
        ...data_filter_params,
        dateOfBirth: dateString,
      }
      this.setState({
        data_filter_params: data_filter_params,
      });
    }
  }

  handleKeyDown = (e) => {
    this.search();
  };

  changeDateOfBirth = (date, dateString) => {
    console.log(date, dateString);
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      dateOfBirth: dateString,
    }
    this.setState({
      data_filter_params: data_filter_params,
    });
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  fetchStation = async () => {
    const id = this.props.params.stationid
    if (id) {

      try {
        const response = await ApiInstance(Api.station, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);
        console.log("res:", response)

        let station;
        station = { ...response };


        this.setState((prevState) => ({
          stationName: station.stationName,
        }));

      } catch (error) { }
    } else {

    }
  };

  render() {
    const { loading, data, columns, stationId, stationName, totalPagination, pageSize, page } = this.state

    return (
      <>
        <CompanyTitle label={stationName} />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <TitleLevel3 label={"Staff"} />
          </Col>
          <Col span={16} style={{ textAlign: "end" }}>
            {stationName && <CoreBackButton path={CustomPath.station} />} 
          </Col>
          <Col span={4}><CoreSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "fullName")}
            placeholder="SearchByName"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}><CoreSearchBox
            size="middle"
            onChange={(values) => this.changeSearchName(values, "phoneNumber")}
            placeholder="SearchByPhoneNumber"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}>
            <DatePicker placeholder={i18next.t('search_by_date')} style={{ height: '40px', width: '100%' }} onChange={(date, dateString) => {
              this.searchByDob(date, dateString)
            }} />
          </Col>
          <Col span={8}>
            <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
          </Col>
          <Col span={4} style={{ textAlign: 'end' }}><CreateButton path={stationId ? CustomPath.staff_create : `/${CustomPath.staff_create}`} /></Col>
          <Col span={24}>
            {
              loading ?
                <Skeleton active />
                :
                <Table
                  pagination={{
                    position: "top" | "bottom",
                    total: totalPagination,
                    current: page,
                    onChange: this.handlePaginationChange,
                    defaultPageSize: pageSize,
                  }}
                  rowKey="id"
                  dataSource={data}
                  columns={columns} />
            }
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(StaffPage);