import { Button, Col, Dropdown, Image, Input, message, Modal, Row, Skeleton, Space, Table, Tag } from 'antd';
import React from 'react';
// import './fuel-point-request.css'
import { DownOutlined } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import i18next from 'i18next';
import image from "../../components/assets/kpay_ss.webp";
import { CoreSearchBox } from '../../components/core-components';
import { TitleLevel3 } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';

class FuelPointRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            on_approve_loading: false,
            on_reject_loading: false,
            driverId: '',
            photo: image,
            data: [],
            page: Constant.page,
            pageSize: Constant.pageSize,
            totalPagination: '',
            searchValue: [],
            data_filter_params: '',
            prevSearchTerm: '',
            pageNumber: '',
            loading: true,
            remark: '',
            remarkVisible: false,
            isDataFetching: true,
            reasonVisible: false,
            reason: '',
            screenshotId : ''
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async (pageNumber) => {
        const { page, user_filter_params, companyId } = this.state;
        const companyid = this.props.companyId

        try {
            const params = {
                ...user_filter_params,
                page: pageNumber ? pageNumber - 1 : page - 1,
            };

            const response = await ApiInstance(Api.fuel_point_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

            if (response && response.content) {
                this.setState((prevState) => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                    isDataFetching: false
                }));
            }
        } catch (error) { }
    };

    handleApprove = async (status, id) => {

        const { screenshotId } = this.state

        this.setState({
            on_approve_loading: true
        })

        const requestData = {
            "id": id,
            "approvalStatus": 'APPROVED',// APPROVED
        }

        try {
            const response = await ApiInstance(
                Api.fuel_point_approve, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
            if (response.status == 200) {
                this.setState({
                    visible: false
                })
                message.success("Successfully Approved")
                this.fetchData()
            }

        } catch (error) {
            console.log(error);

            this.setState({
                on_approve_loading: false,
                visible: false
            })
        }
    };

    handleReject = async (status, id) => {
        this.setState({
            remarkVisible: true,
            screenshotId : id
        })

    };

    submitReject = async () => {
        const { remark , screenshotId} = this.state;

        console.log(remark)

        this.setState({
            on_reject_loading: true
        })

        const requestData = {
            "id": screenshotId,
            "approvalStatus": 'REJECT',
            "remark": remark
        }

        try {
            const response = await ApiInstance(
                Api.fuel_point_approve, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
            if (response.status == 200) {
                this.setState({
                    on_reject_loading: false,
                    remarkVisible: false,
                    visible: false,
                })
                message.success("Successfully Reject")
                this.fetchData()
            }
        } catch (error) {
            console.log(error);

            this.setState({
                on_reject_loading: false,
                remarkVisible: false,
                visible: false,
            })
        }

    }

    search = async (status) => {
        const { page, pageNumber, searchTerm, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                page: pageNumber ? pageNumber - 1 : page - 1,
                status: status
            };

            const response = await ApiInstance(Api.fuel_point_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event, searchName) => {
        let { data_filter_params } = this.state;

        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value,
        }
        console.log(data_filter_params);
        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );
        }
    }

    handleKeyDown = (e) => {
        this.search();
    };

    handleScreenShot = async (request) => {
        console.log(request.id)
        try {
            this.setState({
                visible: true,
                screenshotId: request.id,
                requestScreenshotUrl: 'http://150.95.89.3:9092/common?profile-url=' + request.paymentScreenShotUrl
            })
        } catch (error) {
            console.error(error)
        }
    }



    render() {
        const { data, page, pageSize, visible,
            isDataFetching, on_reject_loading, reasonVisible,
            totalPagination, remarkVisible, requestScreenshotUrl,
            reason } = this.state

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: i18next.t('driver_name'),
                dataIndex: ['driver', 'fullName'],
                key: 'fullName',
            },
            {
                title: i18next.t('phone_number'),
                dataIndex: ['driver', 'phoneNumber'],
                key: 'phoneNumber',
            },
            {
                title: i18next.t('top_up_point'),
                dataIndex: ['topUpPoint'],
                key: 'top_up_point',
            },
            {
                title: i18next.t('screen_shot'),
                key: 'screen_shot',
                dataIndex: '',
                textAlign: 'center',
                // width: 50,
                render: (request) => {
                    return (
                        request.paymentScreenShotUrl ?
                            <Space style={{ textAlign: 'end' }}>
                                <a onClick={() => this.handleScreenShot(request)}><img src='screenshot' /></a>
                            </Space>
                            : ''
                    )
                },
            },
            {
                title: i18next.t('approve_action'),
                key: 'approveAction',
                dataIndex: '',
                // textAlign: 'center',
                width: 50,
                render: (request) => {
                    return (
                        request.paymentScreenShotUrl ?
                            <Space style={{ textAlign: 'end' }}>
                                {
                                    request.approvalStatus === 'PENDING' &&
                                    <>
                                        <Button key="submit" type="primary" onClick={() => this.handleApprove("APPROVED", request.id)}>
                                            {i18next.t('approve')}
                                        </Button>
                                        <Button type='primary' danger key="back" onClick={() => this.handleReject("REJECT", request.id)}>
                                            {i18next.t('reject')}
                                        </Button>
                                    </>
                                }
                            </Space>
                            : ''
                    )
                },
            },
            {
                title: i18next.t('status'),
                dataIndex: "approvalStatus",
                width: 150,
                key: 'status',
                render: (status) => {
                    let color;
                    switch (status) {
                        case 'APPROVED':
                            color = 'green';
                            break;
                        case 'REJECT':
                            color = 'red';
                            break;
                        default:
                            color = 'blue';
                    }

                    return (
                        <Tag color={color} key={status}>
                            {status}
                        </Tag>
                    );
                },
            },
            (data.some(item => item.remark === '' || item.remark === 'REJECT')) && {
                title: i18next.t('reject_reason'),
                dataIndex: 'remark',
                key: 'rejectReason',
                render: (remark) => {
                    return (
                        remark ?
                            <a onClick={() => this.setState({ reasonVisible: true, reason: remark })} style={{ color: 'red' }}>View Reason</a>
                            : ''
                    );
                },
            }
        ].filter(Boolean);

        const items = [
            {
                key: "all",
                label: (
                    <a
                        href="#"
                        onClick={() => {
                            this.search('')
                        }}
                    >
                        {i18next.t('all')}
                    </a>
                ),
            },
            {
                key: "pending",
                label: (
                    <a
                        href="#"
                        onClick={() => {
                            this.search('PENDING')
                        }}
                    >
                        {i18next.t('pending')}
                    </a>
                ),
            },
            {
                key: "approved",
                label: (
                    <a
                        href="#"
                        onClick={() => {
                            this.search('APPROVED')
                        }}
                    >
                        {i18next.t('approved')}
                    </a>
                ),
            },
            {
                key: "reject",
                label: (
                    <a
                        href="#"
                        onClick={() => {
                            this.search('REJECT')
                        }}
                    >
                        {i18next.t('reject')}
                    </a>
                ),
            },
        ];

        return (
            <>
                <Modal
                    title={<h3 style={{ margin: 0, }}>Request Screenshot</h3>}
                    width={500}
                    height={1200}
                    visible={visible}
                    onCancel={() => this.setState({ visible: false })}
                    // onOk={this.handleApprove}
                    footer={null}
                    style={{
                        top: 20,
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'center', background: '#eee' }}>
                        <Image preview={false} src={requestScreenshotUrl} />
                    </div>
                </Modal>
                <Modal
                    title="Remark"
                    onCancel={() => this.setState({ remarkVisible: false })}
                    visible={remarkVisible}
                    footer={[
                        <>
                            <Button loading={on_reject_loading} onClick={this.submitReject} type='primary' danger>{i18next.t('reject')}</Button>
                        </>
                    ]}>
                    <Input style={{ height: "40px" }} placeholder='e.g. Your screenshot is wrong....' onChange={(e) => this.setState({
                        remark: e.target.value
                    })}>

                    </Input>
                </Modal>
                <Modal
                    onCancel={() => this.setState({ reasonVisible: false })}
                    visible={reasonVisible}
                    footer={null}>
                    <span style={{ fontSize: 20, fontWeight: 'bold', color: '#e32605' }}>{reason}</span>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TitleLevel3 label={i18next.t("fuel_point_requests")} />
                    </Col>
                    <Col span={4}><CoreSearchBox
                        size="middle"
                        onChange={(values) =>
                            this.changeSearchName(values, "name")}
                        placeholder="SearchByName"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={4}><CoreSearchBox
                        size="middle"
                        onChange={(values) => this.changeSearchName(values, "phoneNumber")}
                        placeholder="SearchByPhoneNumber"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={12}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >{i18next.t('search')}</Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }} lg={{ span: 4 }}>
                        <Dropdown menu={{ items }} placement="bottomRight" arrow>
                            <Button style={{ height: 40 }} type='primary' danger>
                                <Space>
                                    {i18next.t('status_type')}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col lg={{ span: 24 }}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table pagination={{
                                    position: "top" | "bottom",
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                }}
                                    dataSource={data} columns={columns} />
                        }
                    </Col>
                </Row>
            </>
        )
    }
}
export default compose(withRouter(FuelPointRequests));